import { createContext, useEffect, useRef, useState } from "react";
import { getCurrentUser } from "../services/UserService";
import toast from "react-hot-toast";
import AxiosRequest from "../utils/AxiosRequest";
import qs from "query-string";

export const User = createContext();

export const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationsCount] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);


  const [workspacePopup, setWorkspacePopup] = useState(false)
  const [currentWorkspace, setCurrentWorkspace] = useState(null);

  const handleSelectCurrentWorkspace = (item) => {
    if (item) {
      sessionStorage.setItem("currentWorkspace", JSON.stringify(item));
      setCurrentWorkspace(item);
    }
  };

  useEffect(() => {
    const res = sessionStorage.getItem("currentWorkspace");
    if (res) setCurrentWorkspace(JSON.parse(res))
  }, [sessionStorage.getItem("currentWorkspace")]);

  const _currentUser = () => {
    getCurrentUser()
      .then((res) => {
        setCurrentUser(res);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
        // setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  // ====== get all workspaces ====
  const [allWorkspaces, setAllWorkspaces] = useState({
    data: [],
    loading: false
  })
  const getAllWorkspaces = async ({ query } = {}) => {
    try {
      const q = qs.stringify(query, {
        skipNulls: true,
        skipEmptyString: true,
      });
      setAllWorkspaces(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`/workspace/user-work-spaces?${q}`);
      const selectedWorkspace = data.data.filter((data) => data._id === currentWorkspace._id)
      setCurrentWorkspace(selectedWorkspace[0])
      setAllWorkspaces(prev => ({ ...prev, data: data.data }))
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setAllWorkspaces(prev => ({ ...prev, loading: false }))
    }
  };

  const getNotifications = async () => {
    try {
      const result = await AxiosRequest.get(
        `/notification/getNotificationByUser?page=${page}&limit=20`
      );
      if (page === 1) {
        setNotifications(result.data.data.data);
      } else {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...result.data.data.data,
        ]);
      }
      if (result.data.data.data.length < 20) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setNotificationsCount(result.data.data.unreadbleCount);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("currentUser")) {
      getNotifications();
    }
  }, [page, sessionStorage.getItem("currentUser")]);

  useEffect(() => {
    if (sessionStorage.getItem("currentUser")) {
      _currentUser();
    }
  }, [sessionStorage.getItem("currentUser")]);

  const loadmore = () => {
    setPage(page + 1);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight &&
      hasMore
    ) {
      loadmore();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [notifications, hasMore, containerRef]);

  return (
    <User.Provider
      value={{
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
        _currentUser: _currentUser,
        notifications: notifications,
        getNotifications: getNotifications,
        setNotifications: setNotifications,
        page: page,
        setPage: setPage,
        hasMore: hasMore,
        notificationCount: notificationCount,
        setNotificationCount: setNotificationsCount,
        containerRef: containerRef,
        loadmore: loadmore,
        handleScroll: handleScroll,
        setHasMore: setHasMore,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        currentWorkspace: currentWorkspace,
        setCurrentWorkspace: setCurrentWorkspace,
        handleSelectCurrentWorkspace: handleSelectCurrentWorkspace,
        setWorkspacePopup: setWorkspacePopup,
        workspacePopup: workspacePopup,
        allWorkspaces,
        setAllWorkspaces,
        getAllWorkspaces
      }}
    >
      {children}
    </User.Provider>
  );
};

export default User;
